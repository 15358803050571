@import "../../../variables.less";

.error-page {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  &__reload {
    cursor: pointer;
    color: @red;

    &:hover {
      color: @hoverRed;
    }
  }
}