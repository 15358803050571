@import './semantic-ui/variables.less';
@import './semantic-ui/fonts/font';

.app-content {
  height: 100%;
  margin-left: 80px;
  @media (max-width:1000px) {
    margin-left: 0;
  }
}


@font-face {
  font-family: 't1font';
  src:  url('semantic-ui/fonts/files/t1font.eot?ep57e4');
  src:  url('semantic-ui/fonts/files/t1font.eot?ep57e4#iefix') format('embedded-opentype'),
  url('semantic-ui/fonts/files/t1font.ttf?ep57e4') format('truetype'),
  url('semantic-ui/fonts/files/t1font.woff?ep57e4') format('woff'),
  url('semantic-ui/fonts/files/t1font.svg?ep57e4#t1font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('semantic-ui/fonts/files/Roboto-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('semantic-ui/fonts/files/Roboto-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
}

[class^="t1-font-"], [class*=" t1-font-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 't1font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
