@import "../../../../variables.less";

.error-icon {
  width: 300px;
  height: 300px;

  &__shape {
    fill: none;
    stroke: @red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

