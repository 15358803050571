body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

:root {
  --app-height: 100%;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html,
body {
  padding: 0;
  overflow: hidden !important;
  width: 100vw;
  height: 100vh !important; /* если вдруг браузер не поддерживает Custom Properties */
  height: var(--app-height) !important;
  user-select: none;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, h1, h2, h3, h4, h5, h6 {
  font-family: Roboto,sans-serif;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
