@import '../../semantic-ui//variables.less';

.notifications {
  position: absolute;
  top: 7px;
  right: 20px;
  width: 310px;
  z-index: 9999;
  &__message {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
}

.notifications__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
  padding: 0;
}
